

















import { HelpSucType } from "@/Type/index"
import { Vue,Component, Prop, Watch } from "vue-property-decorator"

@Component({ name:"OrderList" })
export default class OrderList extends Vue{
    OrderList = [
        { title:"订单编号",content:"20110201324" },
        { title:"下单时间",content:"2020-10-23 9:08" },
    ]

    @Prop(Object)
    data!:HelpSucType

    get GetData(){
        return this.data
    }

    @Watch("GetData")
    ChangeData(newVal:HelpSucType){
        this.OrderList[0].content = newVal.orderId as string
        this.OrderList[1].content = newVal.updateTime as string
        let data = this.OrderList
        this.OrderList = data
    }
}

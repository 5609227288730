






















import { HelpSucType } from "@/Type/index"
import { Vue,Component, Prop } from "vue-property-decorator"

 interface HelpSucAddressType<T=string>{
     AddressIcon:T;
 }

 @Component({ name:"HelpSucAddress" })
 export default class HelpSucAddress extends Vue implements HelpSucAddressType{
     AddressIcon = require("$icon/Help/Address.png");

     @Prop(Object)
     data!:HelpSucType

     get GetData(){
         return this.data
     }
 }

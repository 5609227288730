



















































































import { Notify,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HelpSucAddress from "@/components/HelpComponent/HelpSuc/HelpSucAddress.vue"
import HelpSucCont from "@/components/HelpComponent/HelpSuc/HelpSucCont.vue"
import HelpSucComment from "@/components/HelpComponent/HelpSuc/HelpSucComment.vue"
import HelpOrderList from "@/components/HelpComponent/HelpSuc/OrderList.vue"
import { UserType } from "@/Api/Basics/InterfaceType"
import { FillNote } from "@/Note/index"
import Stroage from "@/util/Storage"
import { HelpSucType as HelpSucTypeT } from "@/Type/index"
import { HelpSuc as HelpUpSuc,HelpBefore,HelpAfter,HelpAffirm,HelpAffirmOrder } from "@/Api/Help/index"
import { SendNot } from "@/Api/Basics/index"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";
import {setPullData} from "@/util/compatibility_App";

// let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}//
declare let WeixinJSBridge: any;
declare const document:any;

interface HelpSucType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    FriendIcon :T
    DownIcon:T;
    Show:boolean;
    data:HelpSucTypeT
    OrderData:any
    Affirm:boolean // 是否是从我的接单列表中查看详情
    UserInfo:UserType

    handleToggleShow():void;
    handleUpData():void;
    handleClickPay():void;
    handlePay():void;
    onBridgeReady():void;
    handleCallBack():void;
    handleClickAffirm():void;
    handleError(data:string):void;
}

@Component({ name:"HelpSuc",components:{ HelpSucAddress,HelpSucCont,HelpSucComment,HelpOrderList,HeadTop } })
export default class HelpSuc extends ZoomPage implements HelpSucType{
    loadingShow = true
    userId:string|number = ""
    token = ""
    FriendIcon = require("$icon/Help/Friend.png");
    DownIcon = require("$icon/Basics/Down.png");
    Show = false;
    data:HelpSucTypeT = {}
    OrderData:any = {}
    Affirm = false
    UserInfo:UserType = {}

    activated() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       try{
            this.UserInfo = Stroage.GetData_ && Stroage.GetData_("UserData")
        }catch(cat){ new Error( cat ) }

       if( "take" in this.$route.query ){
           this.Affirm = true
       }else{
           this.Affirm = false
       }

       this.handleUpData()
    }

    handleToggleShow(){
        this.Show = !this.Show
    }

    handleUpData(){
        this.loadingShow = true
        HelpUpSuc({
            userId:this.userId,
            token:this.token
        },{
            id:this.$route.query.id as string
        }).then( res=>{
            this.loadingShow = false
            console.log( res )
            if( res.message.code === "200" ){
                this.data = res.data
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleClickPay(){
        let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
        if ( bool != null && bool[0] == "micromessenger" ){
            this.loadingShow = false
            setPullData({ orderId:this.data.orderId as string })
            return
        }
        this.loadingShow = true
        HelpBefore({
            userId:this.userId,
            token:this.token
        },{
            orderId:this.data.orderId as string
        }).then(res=>{
            if( res.message.code === "200" ){
                this.OrderData = res.data
                this.handlePay()
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

   handlePay(){
       if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
           if( document.addEventListener ){
               document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
           }else if ( "attachEvent" in document ){
               document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
               document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
           }
       }else{
           this.onBridgeReady();
       }
   }

   onBridgeReady(){
       ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
               "appId":this.OrderData.appId,//公众号名称，由商户传入
               "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
               "nonceStr":this.OrderData.nonceStr, //随机串
               "package":this.OrderData.package,
               "signType":this.OrderData.signType,//微信签名方式：
               "paySign":this.OrderData.paySign //微信签名
           },
           (res:any)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                   this.handleCallBack()
               }
           });
   }

   handleCallBack(){
       HelpAfter({
           userId:this.userId,
           token:this.token
       },{
           orderId:this.data.orderId as string
       }).then( res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
           Toast({
               message:"支付成功",
               icon:"none",
               className:"ToastClassName"
           })
           this.handleUpData()
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleClickAffirm(){
       this.loadingShow = true
       new Promise((reslove,reject)=>{
           HelpAffirm({
               userId:this.userId,
               token:this.token
           },{
               id:this.data.id as string
           }).then(res=>{
               if( res.message.code === "200" ){
                   reslove(true)
               }else{
                   reject(res)
               }
           })
       }).then(res=>{
           if ( this.data.destAddressPhone || this.data.userPhone ){
               let Str = FillNote;
               let regPhone = /手机号/
               let regName = /名字/
               Str = Str.replace( regPhone, this.UserInfo.phone + "" )
               Str = Str.replace( regName, this.UserInfo.name || this.UserInfo.nickName + "" )
               SendNot({
                   userId:this.userId,
                   token:this.token
               },{
                   phone:this.data.destAddressPhone as string || this.data.userPhone as string,
                   msg:Str,
               }).then(res=>{
                   this.loadingShow = false
                   if( res.message.code === "200" ){
                       Toast({
                       message:"确认送达",
                       icon:"none",
                       className:"ToastClassName"
                       })
                       this.handleUpData()
                   }else{
                       this.handleError( res.message.msg )
                   }
               })
           }else{
               this.loadingShow = false
               Toast({
               message:"确认送达",
               icon:"none",
               className:"ToastClassName"
               })
               this.handleUpData()
           }
       }).catch(cat=>{
           this.loadingShow = false
           this.handleError( cat.message.msg )
       })
   }

   handleAffirmOrder(){
       this.loadingShow = true
       HelpAffirmOrder({
           userId:this.userId,
           token:this.token
       },{
           id:this.data.id as string
       }).then(res=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               Toast({
                   message:"确认完成",
                   icon:"none",
                   className:"ToastClassName"
               })
               this.handleUpData()
           }else{
               this.handleError( res.message.msg )
           }
       })
   }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
